<template>
  <el-scrollbar class="mark-scrollbar" :native="false">
    <div class="mark-content">
      <div class="mark-info" v-if="Object.keys(headData).length > 0">
        <div class="mark-info-left">
          <div class="left-img">
            <img v-if="headData.avatar" :src="headData.avatar" alt="">
            <img v-else src="./../assets/images/teacher/default-header.png" alt="">
          </div>
        </div>
        <div class="mark-info-right">
          <div class="info-right-top">
            <span class="text-overflow">姓名：{{ headData.nickname }}</span>
            <span>性别：{{ headData.gender }}</span>
            <span>身份证号：{{ headData.id_number }}</span>
          </div>
          <div class="info-right-bottom">
            <span class="text-overflow">考试名称：{{ headData.exam_name }}</span>
            <span>考试日期：{{ headData.exam_time }}</span>
          </div>
        </div>
      </div>
      <div class="askAnswer-content" v-if="Object.keys(answerData).length > 0">
        <div class="askAnswer-title">
          <div class="green-block"></div>
          <span>问答题</span>
          <span>（共{{ answerData.total }}题，共{{ answerData.total_score }}分）</span>
        </div>
        <div class="askAnswer-item-box">
          <div class="askAnswer-item" v-for="(item,index) in answerData.list">
            <div class="question-title">
              <span>{{ index + 1 }}.【问答题】</span>
              <span class="title" v-html="item.title"></span>
              <span>（{{ item.score }}分）</span>
            </div>
            <div class="student-answer">
              <span>考生答案</span>
              <div class="answer-input">
                <el-input type="textarea" :readonly="true" v-model="item.student_answer[0]"
                          :autosize="{ minRows: 4,}"></el-input>
              </div>
            </div>
            <div class="reference-answer">
              <span class="reference-answer-span">参考答案</span>
              <div class="reference-answer-content" v-html="item.analysis"></div>
            </div>
            <div class="grade-score">
              得分
              <el-input-number :controls="false" :min="0" :max="Number(item.score)" v-model="item.student_score" :precision="1"
                               style="width: 100px;margin: 0 8px;text-align: left"></el-input-number>
              分
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button @click="toBack">返回</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="submitPaper">提交</el-button>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import {markSubmitMarkJudge, markGenerateReport, markSubmitMarkTeacher} from '@/utils/apis'

export default {
  name: "correctTheoryPaper",
  data() {
    return {
      role: Number(localStorage.getItem('role')) || ''
    }
  },
  props: ['logId', 'headData', 'answerData'],
  methods: {
    // 提交
    submitPaper() {
      let list = [];
      for (let i = 0; i < this.answerData.list.length; i++) {
        // if (!this.answerData.list[i].student_score) {
        //   this.$message('请为每道试题设置分数');
        //   return
        // }
        let obj = {
          theory_topic_id: this.answerData.list[i].theory_topic_id,
          student_score: this.answerData.list[i].student_score
        }
        list.push(obj)
      }
      let mark_data = JSON.stringify(list);
      let params = {
        student_theory_exam_log_id: this.logId,
        mark_data: mark_data
      }
      if (this.role === 4) {
        markSubmitMarkJudge(params).then((res) => {
          this.$message({
            message: res.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              let params = {
                student_theory_exam_log_id: this.logId
              }
              markGenerateReport(params).then((res2) => {
                this.$router.go(-1)
              }).catch((err2) => {
                console.log('err', err2)
              })
            }
          })
        }).catch((err) => {
          console.log('err', err)
        })
      } else {
        markSubmitMarkTeacher(params).then((res) => {
          this.$message({
            message: res.msg,
            type: 'success',
            duration: 1000,
            onClose: () => {
              let params = {
                student_theory_exam_log_id: this.logId
              }
              markGenerateReport(params).then((res2) => {
                this.$router.go(-1)
              }).catch((err2) => {
                console.log('err', err2)
              })
            }
          })
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 返回
    toBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep p {
  margin: 0;
}

.mark-scrollbar {
  height: 1%;
  flex: 1;

  ::v-deep > .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .mark-content {
    display: flex;
    flex-direction: column;

    .mark-info {
      display: flex;

      .mark-info-left {
        .left-img {
          width: 100px;
          height: 140px;
          box-sizing: border-box;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .mark-info-right {
        display: flex;
        flex: 1;
        width: 1%;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 0;
        box-sizing: border-box;

        .info-right-top, .info-right-bottom {
          padding: 0 64px;
          background: #F3FFF8;
          height: 50px;
          display: flex;
          align-items: center;

          span {
            font-size: 18px;
            display: inline-block;
            width: 30%;
          }

          &.info-right-bottom {
            span {
              &:first-of-type {
                width: 60%;
              }
            }
          }
        }
      }
    }

    .askAnswer-content {
      margin-top: 25px;
      display: flex;
      flex-direction: column;

      .askAnswer-title {
        display: flex;
        align-items: center;

        .green-block {
          width: 5px;
          height: 20px;
          background: #2DC079;
          border-radius: 3px;
          margin-right: 10px;
        }

        span {
          line-height: 1;

          &:first-of-type {
            font-size: 18px;
          }

          &:last-of-type {
            font-size: 16px;
            color: #999;
          }
        }
      }

      .askAnswer-item-box {
        margin-top: 26px;
        display: flex;
        flex-direction: column;

        .askAnswer-item {
          margin-bottom: 46px;

          .question-title {
            padding: 15px 24px;
            background: #F7F7F7;
            font-size: 16px;

            .title {
              ::v-deep p {
                font-size: 16px;
                display: inline;
              }

              ::v-deep img {
                max-width: 300px !important;
                max-height: 300px !important;
              }
            }
          }

          .student-answer {
            display: flex;
            flex-direction: column;
            margin: 0 42px;

            span {
              font-size: 16px;
              line-height: 1;
              margin: 12px 0;
            }

            .answer-input {

            }
          }

          .reference-answer {
            display: flex;
            flex-direction: column;
            margin: 14px 42px;

            .reference-answer-span {
              font-size: 16px;
              color: #FF0000;
              line-height: 1;
              margin-bottom: 4px;
            }

            .reference-answer-content {
              font-size: 14px;
              color: #666666;

              ::v-deep p {
                font-size: 14px;
                display: inline;
                color: #666666;
              }

              ::v-deep img {
                max-width: 300px !important;
                max-height: 300px !important;
              }
            }
          }

          .grade-score {
            margin: 0 42px;
            color: #666666;
            font-size: 16px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .bottom-btn {
      text-align: center;
    }
  }
}
</style>